/* Aktiv-Grotesk-Bold */

@font-face {
  font-family: 'Aktiv-Grotesk-Bold';
  src: url('../fonts/AktivGrotesk_Webfonts_BH/WOFF2/AktivGrotesk_W_Bd.woff2')
      format('woff2'),
    url('../fonts/AktivGrotesk_Webfonts_BH/WOFF/AktivGrotesk_W_Bd.woff')
      format('woff');
}

/* Aktiv Grotesk Roman */

@font-face {
  font-family: 'Aktiv-Grotesk-Roman';
  src: url('../fonts/AktivGrotesk_Webfonts_BH/WOFF2/AktivGrotesk_W_Rg.woff2')
      format('woff2'),
    url('../fonts/AktivGrotesk_Webfonts_BH/WOFF/AktivGrotesk_W_Rg.woff')
      format('woff');
}

/* Aktiv Grotesk Light */

@font-face {
  font-family: 'Aktiv-Grotesk-Light';
  src: url('../fonts/AktivGrotesk_Webfonts_BH/WOFF2/AktivGrotesk_W_Lt.woff2')
      format('woff2'),
    url('../fonts/AktivGrotesk_Webfonts_BH/WOFF/AktivGrotesk_W_Lt.woff')
      format('woff');
}

/* Akzidens Grotesk Light */

@font-face {
  font-family: 'akzidenz-grotesk-pro-condens';
  src: url('../fonts/Berthold_Akzidenz_Grotesk_Medium_Condensed/AkzidenzGroteskBE-MdCn.woff2')
      format('woff2'),
    url('../fonts/Berthold_Akzidenz_Grotesk_Medium_Condensed/AkzidenzGroteskBE-MdCn.woff')
      format('woff');
}