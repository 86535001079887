body {
  &:not(.page-login) {
    font-size: 18px;
    line-height: 1.56;
    font-family: "Aktiv-Grotesk-Light", "Open Sans", "Helvetica", "Arial", sans-serif;
    color: #000000;
    max-width: 1680px !important;
    margin: 0 auto !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    /* word-break:break-word; */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    .h7,
    .h8 {
      font-family: "Aktiv-Grotesk-Bold", "Open Sans", "Helvetica", "Arial", sans-serif;
      color: #000000;
    }
    h1,
    .h1 {
      /* font-size: 60px; */
      font-size: 120px;
      line-height: 0.92;
      letter-spacing: -2px;
      color: #2835ff;
      text-transform: capitalize;
      font-family: "akzidenz-grotesk-pro-condens", "Open Sans", "Helvetica", "Arial", sans-serif;
    }
    h2,
    .h2 {
      /* font-size: 38px; */
      font-size: 75px;
      line-height: 0.99;
      letter-spacing: -2px;
      color: #2835ff;
      text-transform: capitalize;
      font-family: "akzidenz-grotesk-pro-condens", "Open Sans", "Helvetica", "Arial", sans-serif;
    }
    h3,
    .h3 {
      /* font-size: 28px; */
      font-size: 55px;
      line-height: 1.2;
      color: #37db70;
      text-transform: capitalize;
      font-family: "akzidenz-grotesk-pro-condens", "Open Sans", "Helvetica", "Arial", sans-serif;
    }
    h4,
    .h4 {
      /* font-size: 23px; */
      font-size: 45px;
      line-height: 1.47;
      color: #2835ff;
      font-family: "akzidenz-grotesk-pro-condens", "Open Sans", "Helvetica", "Arial", sans-serif;
    }
    h5,
    .h5 {
      font-size: 20px;
      line-height: 1.2;
      font-family: "Aktiv-Grotesk-Bold", "Open Sans", "Helvetica", "Arial", sans-serif;
    }
    h6,
    .h6 {
      font-size: 18px;
      line-height: 1.56;
      color: #37db70;
      font-family: "Aktiv-Grotesk-Bold", "Open Sans", "Helvetica", "Arial", sans-serif;
    }
    .h7 {
      font-size: 16px;
      line-height: 1.625;
      color: #2835ff;
      font-family: "Aktiv-Grotesk-Roman", "Open Sans", "Helvetica", "Arial", sans-serif;
    }
    .h8 {
      font-size: 18px;
      line-height: 1.625;
      color: #2835ff;
      font-family: "Aktiv-Grotesk-Bold", "Open Sans", "Helvetica", "Arial", sans-serif;
    }
    .lead {
      font-size: 24px;
      line-height: 30px;
    }
    p {
      font-size: 16px;
      line-height: 1.6;
    }
    @media all and (max-width: 767px) {
      h1,
      .h1 {
        font-size: 42px;
      }
      h2,
      .h2 {
        font-size: 32px;
      }
      h3,
      .h3 {
        font-size: 24px;
        line-height: 33px;
      }
      .lead {
        font-size: 24px;
        line-height: 25px;
      }
    }
    blockquote {
      font-size: 32px;
      line-height: 27px;
    }
    /*! Typography -- Helpers */
    .type--fine-print {
      font-size: 15px;
    }
    .type--body-font {
      font-family: "Aktiv-Grotesk-Light", "Open Sans", "Helvetica";
    }

    .landing-header {
      background-color: #fff;
      height: 80px;
    }

    .menu-title-gray-500 .menu-item .menu-link {
      font-family: "Aktiv-Grotesk-Bold", "Open Sans", "Roboto", "Helvetica", Sans-Serif;
      color: #585958;
    }

    .landing-header .menu .menu-link.active {
      color: #38db70;
      border-radius: 0px;
      padding-bottom: 20px;
    }

    [data-kt-sticky-landing-header="on"] .landing-header .menu .menu-link.active {
      color: #38db70;
      background-color: #fff;
    }

    .menu-state-title-primary .menu-item .menu-link:hover:not(.disabled):not(.active),
    .menu-state-title-primary .menu-item.hover > .menu-link:not(.disabled):not(.active) {
      color: #585958;
      border-bottom: 3px solid #38db70;
      border-radius: 0px;
    }

    hr {
      width: 240px;
      height: 4px !important;
      opacity: 1 !important;
      border-width: 0;
      background-color: #37db70;
      margin: 10px auto;
    }

    hr.align-left {
      margin-left: 0px;
    }

    h2 {
      line-height: 0.92;
      letter-spacing: -2px;
      color: #2835ff;
      font-family: "akzidenz-grotesk-pro-condens", "Open Sans", "Helvetica", "Arial", sans-serif;
      font-size: 100px !important;
      font-weight: 300;
    }

    .bg-primary {
      background-color: #2835ff !important;
    }

    .text-secondary {
      color: #37db70 !important;
    }

    .countrynav {
      padding-top: 35px;
      padding-bottom: 35px;
      color: #fff !important;
      text-decoration: none;
      font-size: 16px;
    }

    .countrynav ul li {
      border-right: 1px solid #37db70;
      line-height: normal !important;
      padding: 0 6px;
      margin: 0;
      float: left;
    }

    .landing-dark-separator {
      border-top: 1px solid #37db70;
      width: 90%;
      margin: 0 auto;
    }

    [class^="stack-"]:before,
    [class*=" stack-"]:before,
    .socicon {
      color: #37db70 !important;
    }

    [class^="socicon-"],
    [class*=" socicon-"] {
      font-family: "Socicon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
    }

    #about-list {
      list-style-type: none;
      text-decoration: none;
      color: white;
      display: inline-flex;
      padding: 1rem;
    }

    #about-list li a {
      color: white;
      font-size: medium;
      font-weight: bold;
      margin: auto 3rem;
      margin-top: 3rem;
    }
  }
}
